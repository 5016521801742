<template>
    <div>
        <div class="child-top" style="height: 50px;">
        <!--档案类型-->
            <div class="input-from">
                <el-input class="input-with-select" clearable placeholder="请输入关键字" style='width:200px;' 
                      suffix-icon="iconfont iconsousuo"
                          v-model="searchval">
                </el-input>
                <el-button @click="search" class="searchBtn" slot="append" round>搜索</el-button>
            </div>
        </div>
        <div>
            <el-table
                    :empty-text="tableData.length ? '' : '暂无数据'"
                    :data="tableData"
                    class="content-test-table"
                    style="width: 100%"
                    v-loading="tableLoading"
            >
                <el-table-column show-overflow-tooltip type="selection" width="45"></el-table-column>
                <el-table-column label="序号" prop="id" show-overflow-tooltip type="index">
                    <template slot-scope="scope">
                        {{(currentPage1-1)*10+scope.$index+1}}
                    </template>
                </el-table-column>
                <el-table-column label="名称" prop="name" show-overflow-tooltip></el-table-column>
                <el-table-column label="档案顺序" prop="order_no" show-overflow-tooltip></el-table-column>
                <el-table-column label="备注" prop="demo" show-overflow-tooltip></el-table-column>
                <el-table-column label="状态" prop="status" show-overflow-tooltip width="70">
                    <template slot-scope="scope">
                        <el-switch
                                v-show="scope.row.uuid"
                                active-color="#0055E9"
                                :active-value="1"
                                :inactive-value="0"
                                :width="35"
                                @change="change(scope.row)"
                                inactive-color="#ccc"
                                :disabled="$store.getters.permissionsStr('启/停用')"
                                v-model="scope.row.status"
                        ></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" show-overflow-tooltip width="130">
                    <template slot-scope="scope">
                                <!-- v-show="!$store.getters.permissionsStr('编辑')" -->
                        <el-button
                                @click="Edit(scope.row)"
                                class="blueColor right20"
                                size="medium"
                                style="margin-left:0;"
                                type="text"
                                v-no-more-click
                                v-show="scope.row.uuid"
                                :disabled="$store.getters.permissionsStr('编辑')"
                        >编辑
                        </el-button>
                        <!--    v-show="!$store.getters.permissionsStr('删')"-->
<!--                        <el-button-->
<!--                                @click="Delete(scope.row)"-->
<!--                                class="redColor"-->
<!--                                size="medium"-->
<!--                                type="text"-->
<!--                        >删除-->
<!--                        </el-button>-->
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    :current-page.sync="currentPage1"
                    :page-size="10"
                    :total="total"
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    class="table-pagination"
                    layout="total, jumper,  ->, prev, pager, next"
            ></el-pagination>
        </div>
        <div class="pop">
            <el-dialog
                    :title="this.type==='newcreate'?'新增档案类型':'编辑档案类型'"
                    :visible.sync="centerDialogVisible"
                    @close="$refs['form'].resetFields()"
                    center
                    width="60%"
                    :show-close="false"
            >
                <el-form
                        :model="form"
                        :rules="rules"
                        label-position="left"
                        ref="form"
                        style="margin-bottom:20px;"
                        v-loading="formLoading"
                >
                <div style="display: flex;">
                  <el-form-item :label-width="formLabelWidth" label="名称" prop="name" style="flex: 1;">
                        <el-input placeholder="请输入内容" v-model="form.name" style="width: 80%;"></el-input>
                    </el-form-item>
                    <el-form-item :label-width="formLabelWidth" label="顺序" prop="order_no" style="flex: 1;">
                        <el-input auto-complete="off" placeholder="请输入内容" v-model="form.order_no" style="width: 90%;"></el-input>
                    </el-form-item>
                </div>
                <el-form-item :label-width="formLabelWidth" label="备注" style="width: 100%;">
                    <el-input :rows="8" type="textarea" v-model="form.demo" style="width: 95%;"></el-input>
                </el-form-item>
                </el-form>

                <div class="form-bottom-button">
                    <el-button
                            @click="sureDialog"
                            type="primary"
                            v-no-more-click
                            class="save_btn"
                            round
                    >保  存
                    </el-button>
                    <el-button @click="centerDialogVisible = false" class="cancel_btn" round>取 消</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>


<script>
    // Make a request for a user with a given ID

    export default {
        data() {
            return {
                currentPage1: 1, //默认页数
                searchSelect: 1, //顶部搜索框的下拉选择框
                centerDialogVisible: false, //弹框
                searchval: "", //搜索框
                formLabelWidth: "60px", //字和input框的距离
                total: 0,
                tableLoading: true, //loading
                formLoading: false,
                form: {
                    name: "",
                    demo: "",
                    order_no: ""
                },
                tableData: [{uuid:0}],
                ruleForm: {
                    type: ""
                },
                type: "",
                rules: {
                    name: {
                        ...this.$rules.noEmpty,
                        message: "名称不能为空"
                    },
                    order_no: {
                        ...this.$rules.noEmpty,
                        message: "请输入正确的数字",
                        ...this.$rules.isNumber
                    }
                }
            };
        },
        created() {
            this.getList({
                page: this.currentPage1,
                name: this.searchval
            });
        },
        methods: {
            getList(query) {
                this.$axios
                    .post("/p/archive/lists", this.$qs(query))
                    .then(response => {
                        // handle success
                        this.tableData = response.data.data.rows;
                        this.total = response.data.data.total;
                        this.tableLoading = false;
                    })
                    .catch(error => {
                        // handle error
                        console.log(error);
                    })
                    .finally(() => {
                        // always executed
                    });
            },
            handleSizeChange(val) {
                //分页器
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage1 = val;
                this.getList({page: val, name: this.searchval});
            },
            submitForm(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        alert("submit!");
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            search() {
                //搜索内容
                console.log(this.searchval);
                this.currentPage1 = 1;
                this.getList({name: this.searchval, page: this.currentPage1});
                // this.tableData = a
            },
            Preview(row) {
                console.log(row);
            },
            newcreat() {
                //新增
                this.type = "newcreate";
                this.centerDialogVisible = true;
                this.form.name = "";
                this.form.demo = "";
                this.form.order_no = "";
            },
            Edit(row) {
                //编辑
                this.formLoading = true;
                this.type = "editor";
                this.$axios
                    .post("/p/archive/info", this.$qs({uuid: row.uuid}))
                    .then(response => {
                        this.form = response.data.data; //接口返回需要编辑的数据
                        this.formLoading = false;
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.centerDialogVisible = true;
                        this.formLoading = false;
                    });
                this.centerDialogVisible = true;
            },
            sureDialog() {
                let submit = () => {
                    var realLength = 0;
                    var len = this.form.name.length;
                    var charCode = -1;
                    for(var i = 0; i < len; i++){
                      charCode = this.form.name.charCodeAt(i);
                      if (charCode >= 0 && charCode <= 128) {
                        realLength += 1;
                      }else{
                        // 如果是中文则长度加2
                        realLength += 2;
                      }
                    }
                    if(realLength > 50) {
                      return this.$message({
                        type: 'error',
                        message: '档案名称最大长度为50'
                      })
                    }

                    if(!this.$bashiLength(this.form.demo)) {
                      return this.$message({
                        type: 'error',
                        message: '备注最大长度为80'
                      })
                    }

                    //保存弹框触发的事件
                    if (this.type === "newcreate") {
                        //新增弹框的保存
                        this.$axios
                            .post(
                                "/p/archive/add",
                                this.$qs({
                                    name: this.form.name,
                                    demo: this.form.demo,
                                    order_no: this.form.order_no
                                })
                            )
                            .then(response => {
                                if (response.data.code === 0) {
                                    this.getList({
                                        page: this.currentPage1,
                                        name: this.searchval
                                    });
                                    // this.menuResetFormFun(formName);
                                    this.$message({
                                        message: response.data.message,
                                        type: "success"
                                    });
                                } else {
                                    this.$message({
                                        message: response.data.message,
                                        type: "error"
                                    });
                                }
                            })
                            .catch(error => {
                                console.log(error);
                            })
                            .finally(() => {
                                this.centerDialogVisible = false;
                                this.getList({
                                    page: this.currentPage1,
                                    name: this.searchval
                                });
                                this.form.name = "";
                                this.form.demo = "";
                                this.form.order_no = "";
                                //Object.assign(this.$data.form,this.$options.data().form);//清空from里边的值
                            });
                    } else {
                        //编辑弹框的保存
                        this.$axios
                            .post("/p/archive/update", this.$qs({
                                uuid: this.form.uuid,
                                order_no: this.form.order_no,
                                demo: this.form.demo,
                                name: this.form.name
                            }))
                            .then(response => {
                                if (response.data.code === 0) {
                                    this.getList({
                                        name: this.searchval,
                                        page: this.currentPage1
                                    });
                                    // this.menuResetFormFun(formName);
                                    this.$message({
                                        message: response.data.message,
                                        type: "success"
                                    });
                                } else {
                                    this.$message({
                                        message: response.data.message,
                                        type: "error"
                                    });
                                }
                            })
                            .catch(error => {
                                console.log(error);
                            })
                            .finally(() => {
                                this.centerDialogVisible = false;
                                this.getList({
                                    name: this.searchval,
                                    page: this.currentPage1
                                });
                                Object.assign(
                                    this.$data.form,
                                    this.$options.data().form
                                ); //清空from里边的值
                            });
                    }
                };
                this.$refs.form.validate(isEnter => {
                    if (isEnter) {
                        submit();
                    }
                });
            },
            Delete(row) {
                //点击删除
                this.$confirm("此操作将永久删除数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    closeOnClickModal: false,
                    cancelButtonClass: "",
                    center: true
                }).then(() => {
                    this.$axios
                        .post("/p/archive/delete", this.$qs({uuid: row.uuid}))
                        .then(response => {
                            if (response.data.code === 0) {
                                this.$message({
                                    message: response.data.message,
                                    type: "success"
                                });
                            } else {
                                this.$message({
                                    message: response.data.message,
                                    type: "error"
                                });
                            }
                        })
                        .finally(() => {
                            this.currentPage1 = 1;
                            this.getList({
                                name: this.searchval,
                                page: this.currentPage1
                            });
                        });
                });
            },

            change(row) {
                //修改状态
                this.$axios
                    .post("/p/archive/able", this.$qs({uuid: row.uuid}))
                    .then(response => {
                        if (response.data.code === 0) {
                            this.$message({
                                type: "success",
                                message: response.data.message
                            });
                        } else {
                            this.$message({
                                type: "error",
                                message: response.data.message
                            });
                        }
                    })
                    // .catch((error)=> {
                    //   console.log(error);
                    // })
                    .finally(() => {
                        this.getList({
                            name: this.searchval,
                            page: this.currentPage1
                        });
                    });
            }
        }
    };
</script>
<style lang="scss" scoped>
    @import "../../assets/cssFunction.scss";
    @import "../../assets/private_yuan_public.scss";

    .child-top {
      .input-from {
        .searchBtn{
          margin-top: 10px;
        }
      }
      .operation-button {
        // float: left;
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }

    .aboveTableArea {
        border-bottom: 1px solid rgba(227, 227, 227, 1);

        .buttonArea {
            padding-right: 5px;
            @include buttonAreaLength(1);
        }
    }
    .el-dialog{
      .el-form{
        .el-input{
          border: none;
        }
      }
    }

    .el-pagination{
      background: #fff;
      padding: 10px;
      margin-top: 0;
    }
</style>
